import React from 'react'
import ContentLoader from 'react-content-loader'

const TableRowLoader = ({ colSpan }) => {
    return (
        <tr>
            <td colSpan={colSpan}>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={250}
                    // viewBox="0 0 1000 300"
                    backgroundColor="#222327"
                    foregroundColor="#171718"
                >
                    {/* Loader for table body */}
                    {Array.from(Array(5).keys()).map((index) => (
                        <rect
                            key={index}
                            x="0"
                            y={40 + index * 40}
                            rx="4"
                            ry="4"
                            width="100%"
                            height="25"
                        />
                    ))}
                </ContentLoader>
            </td>
        </tr>
    )
}

export default TableRowLoader