import axiosInstance from './api';

const dashboardService = {
    getTrackedProducts: async (customerId) => {
        let endpoint = '/product/GetTrackedProducts';
        if (customerId) {
            endpoint += `/${customerId}`;
        }
        try {
            const response = await axiosInstance.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }

    },

    getRetailerLevelOverview: async (customerId, days) => {
        let endpoint = '/product/GetRetailerLevelOverview';
        if (days) {
            endpoint += `/${days}`;
        }
        if (customerId) {
            endpoint += `/${customerId}`;
        }
        try {
            const response = await axiosInstance.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching retailer level data:', error);
            throw error;
        }
    },

    // Add more methods as needed for your application
};

export default dashboardService;
