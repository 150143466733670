import { useNavigate } from "react-router-dom";
import { signOut } from "../../services/firebase";
import { useEffect, useState, useRef } from "react";
import customerService from "../../services/customer";
import Cookies from 'js-cookie'; // Import js-cookie library

const HeaderUserInfo = ({ userEmail, signOutText, onSelectCustomer }) => {
    const [customer, setCustomer] = useState(null);
    const [showCustomerList, setShowCustomerList] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectedCustomerId, setSelectedCustomerId] = useState(() => {
        const customerIdFromCookie = Cookies.get("selectedCustomerId");
        return customerIdFromCookie || null;
    });
    const [activeCustomer, setActiveCustomer] = useState(null);

    const navigate = useNavigate();
    const customerListRef = useRef(null);
    const buttonRef = useRef(null);

    const handleMouseEnter = (customerId) => {
        setActiveCustomer(customerId);
    };

    const handleMouseLeave = () => {
        setActiveCustomer(null);
    };


    const handleSignOut = async () => {
        await signOut();
        navigate("/login");
    }

    const toggleCustomerList = () => {
        setShowCustomerList(!showCustomerList);
    }

    const handleClickOutside = (event) => {
        if (customerListRef.current && !customerListRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
            setShowCustomerList(false);
        }
    }


    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const data = await customerService.getCustomer(selectedCustomerId);
                setCustomer(data);
                setActiveCustomer(data.id);
                if (data && data.customers) {
                    setCustomerList(data.customers.map(customer => ({
                        id: customer.mongoId,
                        businessName: customer.businessName
                    })));
                }
            } catch (error) {
                // Handle error
                console.error("Error fetching tracked products data:", error);
            }
        };
        fetchCustomer();
    }, [selectedCustomerId]);

    const handleClickCustomer = (customerId) => {
        onSelectCustomer(customerId);
        Cookies.set('selectedCustomerId', customerId);
        setSelectedCustomerId(customerId);
        setShowCustomerList(false);
    };

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    // Filter the customer list based on the search keyword
    const filteredCustomerList = customerList.filter(customer => customer.businessName.toLowerCase().includes(searchKeyword.toLowerCase()));

    return (<div className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col">
            <div className="text-3xl font-bold text-white">
                <span>{customer?.name}</span>
                {customer?.customers?.length > 0 && (
                    <span className="relative">
                        <button className="pl-[14px] pr-[14px] py-[4.75px] h-[28px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s] relative bottom-[6px] left-[15px]" ref={buttonRef} onClick={toggleCustomerList}>Select Customer</button>
                        {showCustomerList && (
                            <div
                                ref={customerListRef}
                                className="absolute w-[200px] top-[0%] left-0 bg-[rgb(0,_0,_0)] rounded-[7px] shadow-dark-box-shadow z-10"
                                style={{ left: "15px" }}
                            >
                                <div className="flex justify-between items-center px-[16px] py-[12px]">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        autoComplete="off"
                                        spellCheck="false"
                                        className="w-full bg-transparent border-[none] text-[16px] font-normal text-[rgb(255,_255,_255)] overflow-hidden whitespace-nowrap overflow-ellipsis focus:outline-none"
                                        defaultValue=""
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                                <ul
                                    empty={filteredCustomerList.length === 0 ? 'true' : 'false'}
                                    height={39}
                                    className="w-full [list-style:none] pt-0 px-[4px] pb-[6px] overflow-y-scroll [scrollbar-width:none]"
                                    style={{ height: "207.5px" }}
                                >
                                    {filteredCustomerList.map((cust, index) => (
                                        <li
                                            key={index}
                                            className={`h-[31px] px-[12px] py-0 text-[rgb(134,_143,_151)] cursor-pointer flex items-center justify-between rounded-[4px] ${activeCustomer === cust.id ? 'bg-[rgba(134,_143,_151,_0.1)]' : 'bg-transparent'}`}
                                            onClick={() => handleClickCustomer(cust.id)}
                                            onMouseEnter={() => handleMouseEnter(cust.id)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[16px]">{cust.businessName}</span>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        )}
                    </span>
                )}

            </div>
            <div className="text-lg text-zinc-600">{userEmail}</div>
        </div>
        <div className="flex gap-3 my-auto text-md cursor-pointer font-medium text-white" onClick={handleSignOut}>
            <div className="grow">{signOutText}</div>
            <img
                loading="lazy"
                src="/assets/icons/signout-icon.svg"
                className="aspect-square w-[22px]"
                alt="User"
            />
        </div>
    </div>)
}

export default HeaderUserInfo;