import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Modal.css";

const Modal = ({ products, showModal, onClose }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const modalRef = useRef(null);

    const navigate = useNavigate();

    const handleClose = () => {
        if (onClose) {
            onClose(); // Call the provided onClose callback if available
        }
    };

    // Event listener to handle clicks outside of the modal
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    };

    const handleRowClick = (_id) => {
        // Construct the URL with the product _id
        const productUrl = `/product/${_id}`;

        // Navigate to the product page
        navigate(productUrl);
    };

    const filteredProducts = products.filter(product => {
        const productNameMatch = product.productName.toLowerCase().includes(searchQuery.toLowerCase());
        const skuMatch = product.sku.toLowerCase().includes(searchQuery.toLowerCase());
        return productNameMatch || skuMatch;
    });

    useEffect(() => {
        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal])

    return (
        <div className={`fixed inset-0 z-50 overflow-y-auto px-4 md:px-8 flex items-center justify-center h-screen ${showModal ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
            <div ref={modalRef} className='flex flex-col w-[712px] border-[1px] border-[solid] border-[rgba(28,29,34,0.6)] [box-shadow:rgba(0,_0,_0,_0.6)_0px_0px_35px] bg-[rgb(0,_0,_0)] rounded-[7px] overflow-hidden'>
                <div className='relative px-[24px] py-[16px] bg-[rgba(11,_11,_15,_0.64)] rounded-tl-[7px] rounded-br-none rounded-tr-[7px] rounded-bl-none'>
                    <div className='absolute w-full max-w-[1312px] h-px bottom-0 left-0 right-0 mx-[auto] my-0 bg-[linear-gradient(90deg,_rgb(17,_17,_20)_0%,_rgb(29,_29,_32)_50%,_rgb(17,_17,_20)_100%),_rgb(217,_217,_217)]'></div>
                    <form className='relative pt-[22px] px-0 pb-[8px]'>
                        <input placeholder='Search for a product or SKU' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className='text-white h-[34px] w-full bg-transparent text-[18px] font-thin border-[none] focus:outline-none' type="text" />
                    </form>
                </div>
                <ul className='[list-style:none] px-[8px] py-0 rounded-tl-none rounded-br-[8px] rounded-tr-none rounded-bl-[8px] bg-[rgb(0,_0,_0)] overflow-y-hidden'>
                    <div className='modal-content-wrapper relative h-[304px] overflow-auto [direction:ltr] pb-[9px]'>
                        <div className='w-full'>
                            <div className='text-[rgb(134,_143,_151)] opacity-[0.64] h-[45px] pt-[16px] px-[16px] pb-0 text-[11px]'>{filteredProducts?.slice(0, 10)?.length === 0 ? "No Results" : "Top Products"}</div>
                            {filteredProducts?.slice(0, 10)?.map((product, index) => {
                                return (
                                    <li onClick={() => { handleRowClick(product._id) }} key={index} className="h-[45px]  px-[16px] py-0 text-[16px] text-[rgb(134,_143,_151)] cursor-pointer rounded-[4px] hover:text-[rgb(255,255,255)] hover:bg-[rgba(38,_39,_47,_0.5)]">
                                        <div className="flex items-center justify-between opacity-100 h-full">
                                            <div className="flex items-center" style={{ maxWidth: 'calc(100% - 250px)' }}>
                                                <span className="truncate mr-[8px]">
                                                    {product.productName}
                                                </span>
                                                <div
                                                    className={`flex ${product.isBenchmarkAvailable ? "px-[5px]" : ""
                                                        } items-center text-[#8C8D8F] h-[20px] text-[10px] not-italic font-bold leading-[normal] ml-[10px] rounded-[5px] bg-[#2B2C30]`}
                                                >
                                                    {product.isBenchmarkAvailable ? "Benchmark" : ""}
                                                </div>
                                            </div>
                                            <div className="flex text-right">
                                                <span className="min-w-[110px]">
                                                    <span className="flex flex-row justify-center items-center border-[1px] px-[10px] border-[solid] border-[rgb(38,39,47)] rounded-[4px] text-[10px] min-w-[100px] h-[28px] leading-[132%] ml-auto bg-transparent">
                                                        {product.sku}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        // <div className={`fixed inset-0 z-50 overflow-y-auto px-4 md:px-8 flex items-center justify-center h-screen ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        //     <div style={{
        //         backgroundColor: "#131419",
        //         borderRadius: "8px",
        //     }} className="shadow-dark-box-shadow relative w-full max-w-sm shadow-lg rounded-md">
        //         <div className="flex justify-end p-4 border-b border-[#222327]">
        //             <button type="button" onClick={handleClose} className="text-gray-400 focus:outline-none hover:text-gray-500">
        //                 <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        //                     <path fill-rule="evenodd" d="M4.707 3.307a1 1 0 00-1.414 1.414L10 11.293l4.293-4.293a1 1 0 00-1.414-1.414L8 6.586l-2.293-2.293a1 1 0 00-1.414 0z" clip-rule="evenodd" />
        //                     <path fill-rule="evenodd" d="M5.707 4.707a1 1 0 011.414 1.414L14.293 13.586l-1.414 1.414a1 1 0 01-1.414-1.414L12.586 12l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        //                 </svg>
        //             </button>
        //         </div>
        //         <div className="p-6">{children}</div>
        //     </div>
        // </div>

    );
};

export default Modal;
