// src/services/api.js
import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: 'https://pw-business-api-v1.azurewebsites.net/api',
});

// Add a response interceptor
instance.interceptors.response.use(
    (response) => {
        // If the response is successful, return it
        return response;
    },
    (error) => {
        // If the response status is 401, redirect to the login page
        if (error.response && error.response.status === 401) {
            // You can replace '/login' with your actual login page route
            window.location.href = '/login';
        }

        // If the error is not a 401, reject with the error
        return Promise.reject(error);
    }
);

instance.interceptors.request.use(
    (config) => {
        // Get the token from the cookie
        const authToken = Cookies.get('authToken');

        // Attach the token to the Authorization header
        config.headers.Authorization = `Bearer ${authToken}`;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
