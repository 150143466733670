import React, { useEffect, useState, useRef } from "react";
import HeaderMenuInfo from "../../components/common/HeaderMenuInfo";
import productService from "../../services/product";
import { useParams, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import "../../styles/ProductDetails.css";
import TableRowLoader from "../../components/common/TableRowLoader";
import ContentLoader from "react-content-loader";
import Cookies from "js-cookie";
import Tooltip from "../../components/common/Tooltip";

const PriceHistory = ({ productStores, storeList }) => {
  const [selectedInterval, setSelectedInterval] = useState("1M"); // Default selected interval
  const [selectedStore, setSelectedStore] = useState(productStores[0]?.store);
  const [graphData, setGraphData] = useState([]);
  const [showStoresList, setShowStoresList] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeStore, setActiveStore] = useState(null);
  const [series, setSeries] = useState([]);

  const storeListRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    filterDataByInterval(selectedInterval, selectedStore);
    setActiveStore(selectedStore);
  }, [selectedInterval, selectedStore]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      storeListRef.current &&
      !storeListRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowStoresList(false);
      setSearchKeyword("");
    }
  };

  const toggleStoresList = () => {
    setShowStoresList(!showStoresList);
  };

  // Filter data based on the selected interval
  const filterDataByInterval = (interval, store) => {
    const currentDate = new Date();
    let startDate;

    const productStore = productStores?.filter((ps) => {
      return ps.store === store;
    })[0];

    switch (interval) {
      case "1D":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 1);
        break;
      case "1W":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 7);
        break;
      case "1M":
        startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() - 1);
        break;
      case "2M":
        startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() - 2);
        break;
      case "3M":
        startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() - 3);
        break;
      case "6M":
        startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() - 6);
        break;
      case "1Y":
        startDate = new Date(currentDate);
        startDate.setFullYear(currentDate.getFullYear() - 1);
        break;
      case "MAX":
        // Find the lowest date from historyData
        const lowestDate = new Date(
          Math.min(
            ...productStore?.historyData.map((item) => new Date(item.createdOn))
          )
        );
        startDate = lowestDate;
        break;
      default:
        // Default case (e.g., for "1M")
        startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() - 1);
    }

    // Filter data based on startDate

    const filteredData = productStore?.historyData.filter(
      (item) => new Date(item.createdOn) >= startDate
    );

    // Calculate average price for each date excluding selected store
    const avgPriceData = [];
    productStores.forEach((ps) => {
      if (ps.store !== store) {
        ps.historyData.forEach((item) => {
          const date = new Date(item.createdOn).toISOString().split("T")[0];
          if (!avgPriceData[date]) {
            avgPriceData[date] = {
              date,
              priceSum: 0,
              count: 0,
            };
          }
          avgPriceData[date].priceSum += item.price;
          avgPriceData[date].count++;
        });
      }
    });

    // Calculate average price for each date
    const averagePrices = Object.values(avgPriceData).map((avg) => ({
      createdOn: avg.date,
      price: avg.priceSum / avg.count,
    }));

    // Merge data for selected store and average prices
    const mergedData = [];
    filteredData.forEach((item) => {
      const avgPriceItem = averagePrices.find(
        (avg) => avg.createdOn === item.createdOn.split("T")[0]
      );
      if (avgPriceItem) {
        mergedData.push({
          ...item,
          avgPrice: avgPriceItem.price,
        });
      }
    });

    console.log(mergedData);

    if (mergedData?.length > 0) {
      setGraphData(mergedData);
    } else {
      setGraphData(filteredData);
    }

    console.log(filteredData);
  };

  useEffect(() => {
    let seriesData = [];

    if (graphData.some((item) => item.hasOwnProperty("avgPrice"))) {
      seriesData = [
        {
          name: selectedStore,
          data: graphData?.map((item) => item.price),
        },
        {
          name: "Price Relativity Cluster",
          data: graphData?.map((item) => item.avgPrice),
          strokeWidth: 10,
        },
      ];
    } else {
      seriesData = [
        {
          name: selectedStore,
          data: graphData?.map((item) => item.price),
        },
      ];
    }

    console.log(graphData.some((item) => item.hasOwnProperty("avgPrice")));
    setSeries(seriesData);
  }, [graphData]);

  // Prepare the options for the chart
  const options = {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      type: "area",
      stacked: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ["#ECBDAA", "#6EBDE6"], // Colors for both series
      curve: "smooth",
      width: [4, 1],
    },
    colors: ["#ECBDAA", "#6EBDE6"],
    grid: {
      show: false,
      padding: {
        left: -7,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      crosshairs: {
        show: true,
        width: 1,
        position: "back",
        opacity: 0.9,
        stroke: {
          color: "#b6b6b6",
          width: 2,
          dashArray: 0,
        },
        fill: {
          type: "solid",
          color: "#B1B9C4",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 5,
          opacity: 0.4,
        },
      },
      categories: graphData?.map((item) => item.createdOn),
      type: "datetime",
      labels: {
        show: false,
      },
      // categories: uniqueCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      x: {
        format: "dd MMMM yyyy",
      },
      y: {
        formatter: function (val) {
          // Format the tooltip to display the label with currency symbol and formatted price
          return `SR ${val.toFixed(2)}`; // Example: SR 25.00
        },
      },
    },
  };

  // Define the CSS background style
  const chartStyle = {
    background:
      "radial-gradient(rgb(40, 41, 46) 1px, transparent 0px) -8.5px -8.5px / 7px 7px transparent",
    maskImage: "radial-gradient(rgb(0, 0, 0), transparent 72%)",
    position: "absolute",
    width: "100%",
    height: "60%",
  };

  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleMouseEnter = (store) => {
    setActiveStore(store);
  };

  const handleMouseLeave = () => {
    setActiveStore(selectedStore);
  };

  // Filter the customer list based on the search keyword
  const filteredStoreList = storeList?.filter((store) =>
    store.store.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const handleClickStore = (store) => {
    setSelectedStore(store);
    setShowStoresList(false);
    setSearchKeyword("");
  };

  const buttonLineStyle = {
    width: "27px",
    borderTop: "2px solid #489FFA",
    position: "absolute",
    top: "-6px",
    left: "50%",
    transform: "translateX(-50%)",
  };

  return (
    <>
      <div className="relative flex items-center mt-[30px]">
        <div className="flex items-center">
          <div className="mr-[16px] whitespace-nowrap">
            <h1 className="text-[20px] font-normal leading-none text-[white]">
              Select store
            </h1>
          </div>
          <div className="flex flex-wrap gap-[8px] pr-[12px]">
            <span>
              <button
                ref={buttonRef}
                onClick={toggleStoresList}
                className="flex flex-row items-center border border-solid bg-dark-button border-dark-button-border pl-[20px] pr-[20px] py-[4.75px] h-[35px] gap-[25px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M1 2.81066C1 1.72426 3.33333 1 8 1C12.6667 1 15 1.72426 15 2.81066C15 4.5 9.55556 6.6 9.55556 8.24262C9.55556 9.6912 9.55556 9.6912 9.55556 10.7776C9.55556 11.864 6.44444 12.9504 6.44444 11.5018C6.44444 10.0533 6.44444 10.0533 6.44444 8.24262C6.44444 6.6 1 4.5 1 2.81066Z"
                    stroke="#565B62"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {selectedStore}
              </button>
              {showStoresList && (
                <div
                  ref={storeListRef}
                  className="absolute w-[200px] top-[0%] left-0 bg-[rgb(0,_0,_0)] rounded-[7px] shadow-dark-box-shadow z-10"
                  style={{ left: "120px" }}
                >
                  <div className="flex justify-between items-center px-[16px] py-[12px]">
                    <input
                      placeholder="Search"
                      type="text"
                      autoComplete="off"
                      spellCheck="false"
                      className="w-full bg-transparent border-[none] text-[16px] font-normal text-[rgb(255,_255,_255)] overflow-hidden whitespace-nowrap overflow-ellipsis focus:outline-none"
                      defaultValue=""
                      onChange={handleSearchInputChange}
                    />
                  </div>
                  <ul
                    empty={filteredStoreList.length === 0 ? "true" : "false"}
                    height={39}
                    className="w-full [list-style:none] pt-0 px-[4px] pb-[6px] overflow-y-scroll [scrollbar-width:none]"
                    style={{ height: "207.5px" }}
                  >
                    {filteredStoreList?.map((store, index) => (
                      <li
                        key={index}
                        className={`h-[31px] px-[12px] py-0 text-[rgb(134,_143,_151)] cursor-pointer flex items-center justify-between rounded-[4px] ${
                          activeStore === store.store
                            ? "bg-[rgba(134,_143,_151,_0.1)]"
                            : "bg-transparent"
                        }`}
                        onClick={() => handleClickStore(store.store)}
                        onMouseEnter={() => handleMouseEnter(store.store)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[16px]">
                          {store.store}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
      <div style={chartStyle}></div>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="400px"
      />
      <div className="relative top-[-1px] intervals flex justify-between items-center">
        <div className="relative -top-px -left-[10.5px]">
          <div className="flex relative pt-[4px]">
            {["1W", "1M", "2M", "3M", "6M", "1Y", "MAX"].map((interval) => (
              <button
                key={interval}
                className={`relative flex items-center justify-center ${
                  selectedInterval === interval
                    ? "text-[rgb(255,_255,_255)]"
                    : "text-[rgb(134,_143,_151)]"
                } p-[10.5px] leading-none [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s] opacity-100`}
                onClick={() => {
                  setSelectedInterval(interval);
                }}
              >
                <div
                  className="line"
                  style={selectedInterval === interval ? buttonLineStyle : null}
                ></div>
                {interval}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Store = ({ productStores, loading }) => {
  return (
    <table className="table-auto w-full mt-[30px] rounded-lg border-separate border-spacing-y-3">
      <thead>
        <tr className="text-white bg-[#17171C] text-[13px]">
          <th className="opacity-50 w-[200px] px-6 py-2 text-left font-normal rounded-tl rounded-bl">
            Store
          </th>
          <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
            Stock Status
          </th>
          <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
            Price
          </th>
          <th className="opacity-50 w-[180px] px-6 py-2 text-center font-normal">
            Historical Lowest Price
          </th>
          <th className="opacity-50 w-[180px] px-6 py-2 text-center font-normal">
            Last Update
          </th>
          <th className="w-[150px] px-6 py-2 text-center font-normal">
            <div className="flex justify-center items-center">
              <div className="opacity-50 ">30-day PVI</div>
            </div>
          </th>
          <th className="w-[150px] px-6 py-2 text-center font-normal">
            <div className="flex justify-center items-center">
              <div className="opacity-50 ">60-day PVI</div>
            </div>
          </th>
          <th className="w-[150px] px-6 py-2 text-center font-normal rounded-tr rounded-br">
            <div className="flex justify-center items-center">
              <div className="opacity-50 ">120-day PVI</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <TableRowLoader colSpan={8} />
        ) : (
          productStores?.map((ps, index) => (
            <ProductStoreRow key={index} {...ps} />
          ))
        )}
      </tbody>
    </table>
  );
};

const ProductBenchmark = ({ products, loading }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedProducts = showAll
    ? products?.competitorProducts
    : products.competitorProducts?.slice(0, 10);

  return (
    <>
      <table className="table-fixed w-full mt-[30px] rounded-lg border-separate border-spacing-y-3">
        <thead>
          <tr className="text-white bg-[#17171C] text-[13px]">
            <th className="opacity-50 w-[250px] px-6 py-2 text-left font-normal rounded-tl rounded-bl">
              Product Name
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              SKU
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Listed Stores
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Lowest Price
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              LPS
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Highest Price
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              HPS
            </th>
            <th className="opacity-50 w-[180px] px-6 py-2 text-center font-normal rounded-tr rounded-br">
              Average Price
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableRowLoader colSpan={8} />
          ) : (
            <ProductBenchmarkRow
              {...products.baseProduct}
              showSampledAvgDiff={false}
            />
          )}
        </tbody>
      </table>
      <table className="table-fixed w-full rounded-lg border-separate border-spacing-y-3">
        <thead>
          <tr className="text-white bg-[#17171C] text-[13px]">
            <th className="opacity-50 w-[250px] px-6 py-2 text-left font-normal rounded-tl rounded-bl">
              Competitor product
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Competitor SKU
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Listed Stores
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Lowest Price
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              LPS
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              Highest Price
            </th>
            <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
              HPS
            </th>
            <th className="w-[180px] px-6 py-2 text-center font-normal rounded-tr rounded-br">
              <div className="flex justify-center items-center">
                <div className="opacity-50 ">Sampled Avg Diff</div>
                <Tooltip text="Sampled Average Difference is a comparison of your product's average price against the average prices of competitors' products, calculated across all listed stores. Positive percentages indicate the competitor is more expensive, while negative percentages suggest they are cheaper on average.">
                  {/* Render the Tooltip component with the desired tooltip text */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g opacity="0.5">
                      <path
                        d="M7.5 1.40625C2.36197 1.40625 1.40625 2.36197 1.40625 7.5C1.40625 12.6285 2.34129 13.5938 7.5 13.5938C12.6185 13.5938 13.5938 12.6285 13.5938 7.5C13.5938 2.3463 12.635 1.40625 7.5 1.40625ZM7.5 3.51885C8.11497 3.51885 8.61352 4.01739 8.61352 4.63236C8.61352 5.24733 8.115 5.74588 7.5 5.74588C6.885 5.74588 6.38648 5.24736 6.38648 4.63236C6.38648 4.01739 6.88503 3.51885 7.5 3.51885ZM9.4715 10.7642C9.4715 11.1585 9.14889 11.4812 8.75458 11.4812H7.88941H7.10458H6.24536C5.85105 11.4812 5.52844 11.1585 5.52844 10.7642V10.7479C5.52844 10.3535 5.85105 10.0309 6.24536 10.0309H6.38766V7.20513H5.88691C5.68978 7.20513 5.52847 7.04382 5.52847 6.84668V6.82119C5.52847 6.62405 5.68978 6.46274 5.88691 6.46274H7.10458H7.5924H7.88941C8.28372 6.46274 8.60634 6.78536 8.60634 7.17967V10.031H8.75458C9.14889 10.031 9.4715 10.3536 9.4715 10.7479V10.7642Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </Tooltip>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableRowLoader colSpan={8} />
          ) : (
            displayedProducts?.map((product, index) => (
              <ProductBenchmarkRow
                key={index}
                {...product}
                showSampledAvgDiff={true}
              />
            ))
          )}
        </tbody>
      </table>
      {products?.competitorProducts?.length > 10 && (
        <button
          onClick={() => setShowAll(!showAll)}
          style={{
            transition: "box-shadow 0.3s", // Add transition for smooth effect
            boxShadow: "none", // Initial box-shadow
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = "rgb(38, 39, 47) 0px 0px 0px 2px";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = "none";
          }}
          className="w-full bg-[#17171C] text-[13px] opacity-50 font-normal text-white rounded-md px-6 py-3 text-center focus:outline-none"
        >
          {showAll ? "Show Less" : "See all products"}
        </button>
      )}
    </>
  );
};

const ProductBenchmarkRow = ({
  _id,
  productName,
  sku,
  listedStores,
  lowestPrice,
  lps,
  highestPrice,
  hps,
  sampledAvgDiff,
  averagePrice,
  showSampledAvgDiff,
}) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    // Construct the URL with the product _id
    const productUrl = `/product/${_id}`;

    // Navigate to the product page
    navigate(productUrl);
  };
  return (
    <tr
      onClick={handleRowClick}
      className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
      style={{
        transition: "box-shadow 0.3s", // Add transition for smooth effect
        boxShadow: "none", // Initial box-shadow
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = "rgb(38, 39, 47) 0px 0px 0px 2px";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <td
        title={productName}
        className="truncate ... px-6 py-2 rounded-tl rounded-bl"
      >
        {productName}
      </td>
      <td className="px-6 py-2 text-center text-xs">{sku}</td>
      <td className="px-6 py-2 text-center text-xs">{listedStores}</td>
      <td className="px-6 py-2 text-center text-xs">{lowestPrice}</td>
      <td className="px-6 py-2 text-center text-xs">{lps}</td>
      <td className="px-6 py-2 text-center text-xs">{highestPrice}</td>
      <td className="px-6 py-2 text-center text-xs">{hps}</td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          showSampledAvgDiff &&
          (sampledAvgDiff < 0 ? "text-[#48CFA1]" : "text-[#D84F68]")
        } rounded-tr rounded-br`}
      >
        {showSampledAvgDiff ? sampledAvgDiff + "%" : averagePrice}
      </td>
    </tr>
  );
};

const ProductStoreRow = ({
  historicalLowestPriceCurrency,
  lastUpdated,
  priceWithCurrency,
  pvI30,
  pvI60,
  pvI120,
  stockStatus,
  store,
  storeURL,
}) => {
  // Format the lastUpdated date
  const formattedLastUpdated = new Date(lastUpdated).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <tr
      className="text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
      style={{
        transition: "box-shadow 0.3s", // Add transition for smooth effect
        boxShadow: "none", // Initial box-shadow
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = "rgb(38, 39, 47) 0px 0px 0px 2px";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <td className="truncate ... px-6 py-2 rounded-tl rounded-bl">
        <a href={storeURL} target="_blank" rel="noreferrer">
          {store}
        </a>
      </td>
      <td className="px-6 py-2 text-center text-xs">{stockStatus}</td>
      <td className="px-6 py-2 text-center text-xs">{priceWithCurrency}</td>
      <td className="px-6 py-2 text-center text-xs">
        {historicalLowestPriceCurrency}
      </td>
      <td className="px-6 py-2 text-center text-xs">{formattedLastUpdated}</td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          pvI30 <= 10 ? "text-[#48CFA1]" : "text-[#D84F68]"
        } `}
      >
        {pvI30 === 0 ? "-" : pvI30}
      </td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          pvI60 <= 10 ? "text-[#48CFA1]" : "text-[#D84F68]"
        } `}
      >
        {pvI60 === 0 ? "-" : pvI60}
      </td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          pvI120 <= 10 ? "text-[#48CFA1]" : "text-[#D84F68]"
        } rounded-tr rounded-br`}
      >
        {pvI120 === 0 ? "-" : pvI120}
      </td>
    </tr>
  );
};

const ProductDetails = () => {
  const { productVariantId } = useParams();
  const [activeButton, setActiveButton] = useState("Stores");
  const [loading, setLoading] = useState(true);
  const [productStores, setProductStores] = useState([]);
  const [productBenchmark, setProductBenchmark] = useState([]);
  const [storeList, setStoreList] = useState([]);
  // Initialize selectedCustomerId state with value from cookies if available, otherwise null
  const [selectedCustomerId, setSelectedCustomerId] = useState(() => {
    const customerIdFromCookie = Cookies.get("selectedCustomerId");
    return customerIdFromCookie || null;
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProductStores = async () => {
      try {
        if (!productVariantId) {
          navigate("/"); // Navigate to home page if productVariantId is not available
          return;
        }
        const [productStoresData, productBenchmarkData] = await Promise.all([
          productService.getProductStores(productVariantId),
          productService.getProductBenchmark(
            selectedCustomerId,
            productVariantId
          ),
        ]);

        setProductStores(productStoresData);
        setProductBenchmark(productBenchmarkData);
        setLoading(false);
        if (productStoresData) {
          setStoreList(
            productStoresData.map((store) => ({
              store: store.store,
            }))
          );
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching tracked products data:", error);
        setLoading(false);
      }
    };
    fetchProductStores();
  }, [productVariantId]);
  return (
    <div className="w-full h-full min-h-screen overflow-scroll px-[80px] py-0 [scrollbar-width:none]">
      <HeaderMenuInfo handleActiveScreen={setActiveButton} />
      <div className="flex flex-col relative mt-[2px] mx-[auto] mb-0 shadow-dark-box-shadow">
        <div className="px-[80px] py-0 mx-[auto] rounded-[7px] rounded-[7px] my-0 w-full h-full min-h-[calc(-32px + 100vh)] flex flex-col bg-[rgb(19,_20,_25)]">
          <div className="w-full px-0 py-[50px] mx-[auto] my-0">
            <h1 className="text-sm font-light text-white max-md:max-w-full">
              {loading ? (
                <ContentLoader
                  speed={2}
                  width={400}
                  height={30}
                  // viewBox="0 0 400 160"
                  backgroundColor="#222327"
                  foregroundColor="#171718"
                >
                  <rect x="0" y="8" rx="3" ry="3" width="150" height="20" />
                </ContentLoader>
              ) : (
                productStores[0]?.sku
              )}
            </h1>
            <h2 className="text-2xl text-white font-bold max-md:max-w-full w-[500px]">
              {loading ? (
                <ContentLoader
                  speed={2}
                  width={400}
                  height={50}
                  // viewBox="0 0 400 160"
                  backgroundColor="#222327"
                  foregroundColor="#171718"
                >
                  <rect x="0" y="8" rx="3" ry="3" width="400" height="30" />
                </ContentLoader>
              ) : (
                productStores[0]?.product
              )}
            </h2>
            {activeButton === "Stores" && (
              <Store loading={loading} productStores={productStores} />
            )}
            {activeButton === "Price History" && productStores?.length > 0 && (
              <PriceHistory
                productStores={productStores}
                storeList={storeList}
              />
            )}

            {activeButton === "Benchmark" && (
              <>
                {productBenchmark?.competitorProducts?.length > 0 ? (
                  <ProductBenchmark
                    loading={loading}
                    products={productBenchmark}
                  />
                ) : (
                  <div className="flex text-white text-[16px] opacity-50 mt-[40px]">
                    No competitor benchmark found for this product
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
