import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const HeaderMenuInfo = ({ handleActiveScreen }) => {
    const [activeButton, setActiveButton] = useState('Stores');

    const navigate = useNavigate();

    const handleBackToHomePage = () => {
        navigate('/'); // Replace '/' with the path of your home page
    };

    useEffect(() => {
        handleActiveScreen(activeButton)
    }, [activeButton])

    const buttonLineStyle = {
        width: '50px',
        borderBottom: '2px solid #489FFA',
        position: 'absolute',
        bottom: "-6px",
        left: "50%",
        transform: "translateX(-50%)"
    };

    return (
        <div className='sticky top-0 w-full py-0 mx-[auto] my-0'>
            <div className='relative flex justify-start items-center h-[80px] my-0 max-w-[1312px]'>
                <div className='bg-dark-button-border mr-[22px] rounded-[50%] flex items-center text-[16px] p-[2px] animate-[0.16s_ease_0s_1_normal_forwards_running_cBjGZz]'>
                    <button className='grid place-items-center w-[32px] h-[32px] rounded-[5px] bg-transparent [transition:background_0.25s_ease_0s]' onClick={handleBackToHomePage}>
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 5.75L7.75 12L14 18.25" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    </button>
                </div>
                <div className='flex items-center animate-[0.16s_ease_0s_1_normal_forwards_running_cBjGZz]'>
                    <div className='relative flex text-white'>
                        <button
                            className={`relative -top-px px-[24px] py-[25px] font-medium ${activeButton === 'Stores' ? '' : 'opacity-50'
                                } [transition:color_0.2s_ease_0s]`}
                            onClick={() => setActiveButton('Stores')}
                        >
                            Stores
                            <div className="line" style={activeButton === 'Stores' ? buttonLineStyle : null}></div>
                        </button>
                        <button
                            className={`relative -top-px px-[24px] px-[25px] font-medium ${activeButton === 'Price History' ? '' : 'opacity-50'
                                } [transition:color_0.2s_ease_0s]`}
                            onClick={() => setActiveButton('Price History')}
                        >
                            Price History
                            <div className="line" style={activeButton === 'Price History' ? buttonLineStyle : null}></div>
                        </button>
                        <button
                            className={`relative -top-px px-[24px] px-[25px] font-medium ${activeButton === 'Benchmark' ? '' : 'opacity-50'
                                } [transition:color_0.2s_ease_0s]`}
                            onClick={() => setActiveButton('Benchmark')}
                        >
                            Benchmark
                            <div className="line" style={activeButton === 'Benchmark' ? buttonLineStyle : null}></div>
                        </button>
                        <button
                            className={`relative -top-px px-[24px] px-[25px] font-medium ${activeButton === 'Reviews' ? '' : 'opacity-50'
                                } [transition:color_0.2s_ease_0s]`}
                            onClick={() => setActiveButton('Reviews')}
                        >
                            Reviews
                            <div className="line" style={activeButton === 'Reviews' ? buttonLineStyle : null}></div>
                        </button>
                        <div className='text-[8px] flex items-center w-[64px] h-[18.173px] flex-shrink-0 rounded-[3px] bg-dark-button-border text-[#8C8D8F] not-italic font-bold leading-[normal] m-auto p-[8px] relative -left-[15px]'>coming soon</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HeaderMenuInfo