// src/components/Profile.js
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import dashboardService from "../../services/dashboard";
import { Context as AuthContext } from "../../context/AuthContext";
import Sidebar from "../../components/common/Sidebar";
import HeaderUserInfo from "../../components/common/HeaderUserInfo";
import ContentLoader from "react-content-loader";
import TableRowLoader from "../../components/common/TableRowLoader";
import Cookies from "js-cookie";
import Modal from "../../components/common/Modal";
import Tooltip from "../../components/common/Tooltip";

const TrackedProductRow = ({
  _id,
  productName,
  listedStores,
  inStock,
  inStockPercentage,
  lowestPrice,
  lps,
  highestPrice,
  hps,
  priceVolatilityIndex,
  isBenchmarkAvailable,
}) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    // Construct the URL with the product _id
    const productUrl = `/product/${_id}`;

    // Navigate to the product page
    navigate(productUrl);
  };
  return (
    <tr
      onClick={handleRowClick}
      className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
      style={{
        transition: "box-shadow 0.3s", // Add transition for smooth effect
        boxShadow: "none", // Initial box-shadow
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = "rgb(38, 39, 47) 0px 0px 0px 2px";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <td title={productName} className="px-6 py-2 rounded-tl rounded-bl">
        <div className="flex">
          <div className="truncate ...">{productName}</div>
          <div
            className={`flex ${
              isBenchmarkAvailable ? "px-[5px]" : ""
            } items-center text-[#8C8D8F] text-[10px] not-italic font-bold leading-[normal] ml-[10px] rounded-[5px] bg-[#2B2C30]`}
          >
            {isBenchmarkAvailable ? "Benchmark" : ""}
          </div>
        </div>
      </td>
      <td className="px-6 py-2 text-center text-xs">{listedStores}</td>
      <td className="px-6 py-2 text-center text-xs">
        <span className="text-xs">{inStock}</span>{" "}
        <span className="ml-1 text-[11px] opacity-50">
          ({inStockPercentage}%)
        </span>
      </td>
      <td className="px-6 py-2 text-center text-xs">{lowestPrice}</td>
      <td className="px-6 py-2 text-center text-xs">{lps}</td>
      <td className="px-6 py-2 text-center text-xs">{highestPrice}</td>
      <td className="px-6 py-2 text-center text-xs">{hps}</td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          priceVolatilityIndex <= 10 ? "text-[#48CFA1]" : "text-[#D84F68]"
        } rounded-tr rounded-br`}
      >
        {priceVolatilityIndex}
      </td>
    </tr>
  );
};

const RetailerLevelOverviewRow = ({
  storeName,
  trackedProducts,
  bestPriceLeader,
  avgPriceDifferential,
  avgPriceChange,
  inStockRate,
  priceVolatilityIndex,
  isHidden,
}) => {
  return (
    <tr
      className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
      style={{
        transition: "box-shadow 0.3s", // Add transition for smooth effect
        boxShadow: "none", // Initial box-shadow
        filter: isHidden ? "blur(5px)" : "none", // Apply blur if isHidden is true
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = "rgb(38, 39, 47) 0px 0px 0px 2px";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <td className="px-6 py-2 rounded-tl rounded-bl">{storeName}</td>
      <td className="px-6 py-2 text-center text-xs">
        {isHidden ? "-" : trackedProducts}
      </td>
      <td className="px-6 py-2 text-center text-xs">
        {isHidden ? "-" : bestPriceLeader + "%"}
      </td>
      <td
        className={`px-6 py-2 text-center text-xs ${
          isHidden
            ? "-"
            : avgPriceDifferential < 0
            ? "text-[#48CFA1]"
            : "text-[#D84F68]"
        }`}
      >
        {isHidden
          ? "-"
          : avgPriceDifferential === null
          ? "-"
          : avgPriceDifferential + "%"}
      </td>
      <td className="px-6 py-2 text-center text-xs">
        {isHidden ? "-" : inStockRate + "%"}
      </td>
      <td className={`px-6 py-2 text-center text-xs rounded-tr rounded-br`}>
        {isHidden ? "-" : avgPriceChange > 0 ? avgPriceChange : "-"}
      </td>
    </tr>
  );
};

const RetailerLevelOverview = ({ customerId }) => {
  const [selectedDays, setSelectedDays] = useState([
    { label: "Today", value: 1 },
    { label: "Past 30 days", value: 30 },
    { label: "Past 60 days", value: 60 },
    { label: "Past 90 days", value: 90 },
  ]);
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [showDaysList, setShowDaysList] = useState(false);
  const [activeDay, setActiveDay] = useState({
    label: "Past 30 days",
    value: 30,
  });
  const [selectedDay, setSelectedDay] = useState({
    label: "Past 30 days",
    value: 30,
  });

  const daysListRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const fetchRetailerLevelOverview = async () => {
      try {
        setLoading(true);
        const data = await dashboardService.getRetailerLevelOverview(
          customerId,
          selectedDay.value
        );
        setStores(data);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error("Error fetching retailer level overview data:", error);
        setLoading(false);
      }
    };

    fetchRetailerLevelOverview();
  }, [customerId, selectedDay]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      daysListRef.current &&
      !daysListRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDaysList(false);
    }
  };

  const toggleDaysList = () => {
    setShowDaysList(!showDaysList);
  };

  const handleClickDay = (day) => {
    setSelectedDay(day);
    setShowDaysList(false);
  };

  const handleMouseEnter = (day) => {
    setActiveDay(day);
  };

  const handleMouseLeave = () => {
    setActiveDay(selectedDay);
  };

  return (
    <section
      className="mt-10 max-md:mt-10 max-md:max-w-full shadow-dark-box-shadow"
      style={{
        backgroundColor: "#131419",
        padding: "53px 40px 54px 60px",
        // boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.13)",
        borderRadius: "8px",
      }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-bold text-white leading-[21px]">
          Retailer Level Overview
          <p
            style={{
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "15px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#4D5259",
            }}
            className="mt-2"
          >
            {loading ? (
              <ContentLoader
                speed={2}
                width={400}
                height={20}
                // viewBox="0 0 400 160"
                backgroundColor="#222327"
                foregroundColor="#171718"
              >
                <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
              </ContentLoader>
            ) : (
              stores.length.toLocaleString() + " Stores"
            )}
          </p>
        </h1>
        <div className="relative flex items-center">
          <button
            ref={buttonRef}
            onClick={toggleDaysList}
            className="flex flex-row items-center border border-solid bg-dark-button border-dark-button-border pl-[20px] pr-[20px] py-[4.75px] h-[35px] gap-[10px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.93229 2.50221C1.23696 3.15891 0.923077 4.28742 0.923077 6.30769C0.923077 8.32796 1.23696 9.45647 1.93229 10.1132C2.63634 10.7781 3.85211 11.0769 6 11.0769C8.14656 11.0769 9.36977 10.7789 10.0761 10.1439C10.7658 9.52386 11.0769 8.47387 11.0769 6.61539C11.0769 4.43461 10.761 3.22713 10.0599 2.53314C9.35765 1.83796 8.14897 1.53846 6 1.53846C3.85211 1.53846 2.63634 1.83727 1.93229 2.50221ZM1.29848 1.83112C2.28674 0.897769 3.8402 0.615385 6 0.615385C8.15872 0.615385 9.71928 0.897084 10.7093 1.87712C11.7006 2.85835 12 4.42009 12 6.61539C12 8.51758 11.6957 9.92913 10.6932 10.8304C9.70715 11.7168 8.16113 12 6 12C3.8402 12 2.28674 11.7176 1.29848 10.7843C0.301503 9.84267 0 8.35579 0 6.30769C0 4.25959 0.301503 2.77271 1.29848 1.83112Z"
                fill="#868F97"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.84615 0C8.10106 0 8.30769 0.206638 8.30769 0.461538V2.30769C8.30769 2.56259 8.10106 2.76923 7.84615 2.76923C7.59125 2.76923 7.38462 2.56259 7.38462 2.30769V0.461538C7.38462 0.206638 7.59125 0 7.84615 0Z"
                fill="#868F97"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.15385 0C4.40875 0 4.61538 0.206638 4.61538 0.461538V2.30769C4.61538 2.56259 4.40875 2.76923 4.15385 2.76923C3.89895 2.76923 3.69231 2.56259 3.69231 2.30769V0.461538C3.69231 0.206638 3.89895 0 4.15385 0Z"
                fill="#868F97"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.92308 4.30769C2.92308 4.05279 3.12971 3.84615 3.38462 3.84615H8.61539C8.87029 3.84615 9.07692 4.05279 9.07692 4.30769C9.07692 4.56259 8.87029 4.76923 8.61539 4.76923H3.38462C3.12971 4.76923 2.92308 4.56259 2.92308 4.30769Z"
                fill="#868F97"
              />
            </svg>
            {selectedDay.label}
          </button>
          {showDaysList && (
            <div
              ref={daysListRef}
              className="absolute w-[150px] h-[135px] top-[0%] left-0 bg-[rgb(0,_0,_0)] rounded-[7px] shadow-dark-box-shadow z-10"
              style={{ top: "35px" }}
            >
              <ul
                empty={selectedDays.length === 0 ? "true" : "false"}
                height={39}
                className="w-full [list-style:none] pt-[5px] px-[4px] pb-[6px] overflow-y-scroll [scrollbar-width:none]"
                style={{ height: "200.5px" }}
              >
                {selectedDays?.map((day, index) => (
                  <li
                    key={index}
                    className={`h-[31px] px-[12px] py-0 text-[rgb(134,_143,_151)] cursor-pointer flex items-center justify-between rounded-[4px] ${
                      activeDay.value === day.value
                        ? "bg-[rgba(134,_143,_151,_0.1)]"
                        : "bg-transparent"
                    }`}
                    onClick={() => handleClickDay(day)}
                    onMouseEnter={() => handleMouseEnter(day)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[16px]">
                      {day.label}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8">
        <table className="table-fixed w-full rounded-lg border-separate border-spacing-y-3">
          <thead>
            <tr className="text-white bg-[#17171C] text-[13px]">
              <th className="opacity-50 w-[250] px-6 py-2 text-left font-normal rounded-tl rounded-bl">
                Store
              </th>
              <th className="opacity-50 w-[200px] px-6 py-2 text-center font-normal">
                Tracked Products
              </th>
              <th className="w-[200px] px-6 py-2 text-center font-normal">
                <div className="flex justify-center items-center">
                  <div className="opacity-50">Best Price Leader</div>
                  <Tooltip text="The percentage of products for which a retailer offers the best (lowest) price compared to the other retailers.">
                    {/* Render the Tooltip component with the desired tooltip text */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g opacity="0.5">
                        <path
                          d="M7.5 1.40625C2.36197 1.40625 1.40625 2.36197 1.40625 7.5C1.40625 12.6285 2.34129 13.5938 7.5 13.5938C12.6185 13.5938 13.5938 12.6285 13.5938 7.5C13.5938 2.3463 12.635 1.40625 7.5 1.40625ZM7.5 3.51885C8.11497 3.51885 8.61352 4.01739 8.61352 4.63236C8.61352 5.24733 8.115 5.74588 7.5 5.74588C6.885 5.74588 6.38648 5.24736 6.38648 4.63236C6.38648 4.01739 6.88503 3.51885 7.5 3.51885ZM9.4715 10.7642C9.4715 11.1585 9.14889 11.4812 8.75458 11.4812H7.88941H7.10458H6.24536C5.85105 11.4812 5.52844 11.1585 5.52844 10.7642V10.7479C5.52844 10.3535 5.85105 10.0309 6.24536 10.0309H6.38766V7.20513H5.88691C5.68978 7.20513 5.52847 7.04382 5.52847 6.84668V6.82119C5.52847 6.62405 5.68978 6.46274 5.88691 6.46274H7.10458H7.5924H7.88941C8.28372 6.46274 8.60634 6.78536 8.60634 7.17967V10.031H8.75458C9.14889 10.031 9.4715 10.3536 9.4715 10.7479V10.7642Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </div>
              </th>
              <th className="w-[200px] px-6 py-2 text-center font-normal">
                <div className="flex justify-center items-center">
                  <div className="opacity-50 ">Avg. Price Differential</div>
                  <Tooltip text="The average percentage difference in prices between retailers across all tracked products. Positive numbers mean the retailer is more expensive on average, negative numbers mean it is cheaper on average.">
                    {/* Render the Tooltip component with the desired tooltip text */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g opacity="0.5">
                        <path
                          d="M7.5 1.40625C2.36197 1.40625 1.40625 2.36197 1.40625 7.5C1.40625 12.6285 2.34129 13.5938 7.5 13.5938C12.6185 13.5938 13.5938 12.6285 13.5938 7.5C13.5938 2.3463 12.635 1.40625 7.5 1.40625ZM7.5 3.51885C8.11497 3.51885 8.61352 4.01739 8.61352 4.63236C8.61352 5.24733 8.115 5.74588 7.5 5.74588C6.885 5.74588 6.38648 5.24736 6.38648 4.63236C6.38648 4.01739 6.88503 3.51885 7.5 3.51885ZM9.4715 10.7642C9.4715 11.1585 9.14889 11.4812 8.75458 11.4812H7.88941H7.10458H6.24536C5.85105 11.4812 5.52844 11.1585 5.52844 10.7642V10.7479C5.52844 10.3535 5.85105 10.0309 6.24536 10.0309H6.38766V7.20513H5.88691C5.68978 7.20513 5.52847 7.04382 5.52847 6.84668V6.82119C5.52847 6.62405 5.68978 6.46274 5.88691 6.46274H7.10458H7.5924H7.88941C8.28372 6.46274 8.60634 6.78536 8.60634 7.17967V10.031H8.75458C9.14889 10.031 9.4715 10.3536 9.4715 10.7479V10.7642Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </div>
              </th>
              <th className="opacity-50 w-[150px] px-6 py-2 text-center font-normal">
                In-Stock Rate
              </th>
              <th className="w-[200px] px-6 py-2 text-center font-normal rounded-tr rounded-br">
                <div className="flex justify-center items-center">
                  <div className="opacity-50 ">Avg. Price Changes</div>
                  <Tooltip text="The average number of times the retailer changes the price of an individual product within the selected timeframe.">
                    {/* Render the Tooltip component with the desired tooltip text */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g opacity="0.5">
                        <path
                          d="M7.5 1.40625C2.36197 1.40625 1.40625 2.36197 1.40625 7.5C1.40625 12.6285 2.34129 13.5938 7.5 13.5938C12.6185 13.5938 13.5938 12.6285 13.5938 7.5C13.5938 2.3463 12.635 1.40625 7.5 1.40625ZM7.5 3.51885C8.11497 3.51885 8.61352 4.01739 8.61352 4.63236C8.61352 5.24733 8.115 5.74588 7.5 5.74588C6.885 5.74588 6.38648 5.24736 6.38648 4.63236C6.38648 4.01739 6.88503 3.51885 7.5 3.51885ZM9.4715 10.7642C9.4715 11.1585 9.14889 11.4812 8.75458 11.4812H7.88941H7.10458H6.24536C5.85105 11.4812 5.52844 11.1585 5.52844 10.7642V10.7479C5.52844 10.3535 5.85105 10.0309 6.24536 10.0309H6.38766V7.20513H5.88691C5.68978 7.20513 5.52847 7.04382 5.52847 6.84668V6.82119C5.52847 6.62405 5.68978 6.46274 5.88691 6.46274H7.10458H7.5924H7.88941C8.28372 6.46274 8.60634 6.78536 8.60634 7.17967V10.031H8.75458C9.14889 10.031 9.4715 10.3536 9.4715 10.7479V10.7642Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <TableRowLoader colSpan={6} />
            ) : (
              stores?.map((store, index) => (
                <RetailerLevelOverviewRow key={index} {...store} />
              ))
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const ProductTracker = ({ customerId }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchTrackedProducts = async () => {
      try {
        setLoading(true);
        const data = await dashboardService.getTrackedProducts(customerId);
        setProducts(data);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error("Error fetching tracked products data:", error);
        setLoading(false);
      }
    };

    fetchTrackedProducts();
  }, [customerId]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const displayedProducts = showAll ? products : products.slice(0, 10);

  return (
    <section
      className="mt-10 max-md:mt-10 max-md:max-w-full shadow-dark-box-shadow"
      style={{
        backgroundColor: "#131419",
        padding: "53px 40px 54px 60px",
        // boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.13)",
        borderRadius: "8px",
      }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-bold text-white leading-[21px]">
          Product Dashboard
          <p
            style={{
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "15px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#4D5259",
            }}
            className="mt-2"
          >
            {loading ? (
              <ContentLoader
                speed={2}
                width={400}
                height={20}
                // viewBox="0 0 400 160"
                backgroundColor="#222327"
                foregroundColor="#171718"
              >
                <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
              </ContentLoader>
            ) : (
              products.length.toLocaleString() + " Products"
            )}
          </p>
        </h1>
        <div className="relative flex items-center">
          <button
            onClick={handleOpenModal}
            className="flex flex-row items-center border border-solid bg-dark-button border-dark-button-border  px-[20px] py-[4.75px] h-[35px] gap-[10px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s]"
          >
            Search
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="3688046_ecommerce_find_magnify_search_ui_icon 1">
                <path
                  id="Vector"
                  d="M7.49999 1C6.45135 0.998202 5.41785 1.25014 4.48768 1.73431C3.55751 2.21848 2.75829 2.92051 2.15823 3.78049C1.55817 4.64047 1.17508 5.63286 1.04166 6.67297C0.908237 7.71308 1.02844 8.77004 1.39202 9.75363C1.75559 10.7372 2.35174 11.6182 3.12959 12.3215C3.90744 13.0248 4.84389 13.5294 5.85903 13.7923C6.87417 14.0553 7.93785 14.0687 8.9593 13.8315C9.98074 13.5943 10.9296 13.1134 11.725 12.43L14.15 14.855C14.2456 14.9369 14.3687 14.9797 14.4945 14.9749C14.6204 14.97 14.7397 14.9178 14.8288 14.8288C14.9178 14.7397 14.97 14.6204 14.9748 14.4945C14.9797 14.3687 14.9369 14.2457 14.855 14.15L12.43 11.725C13.2371 10.7814 13.7571 9.62631 13.9283 8.39646C14.0996 7.1666 13.915 5.91343 13.3963 4.7852C12.8777 3.65697 12.0468 2.7009 11.0019 2.03009C9.95692 1.35929 8.74171 1.00183 7.49999 1ZM7.49999 13C6.41219 13 5.34882 12.6774 4.44435 12.0731C3.53988 11.4687 2.83493 10.6098 2.41865 9.60476C2.00237 8.59977 1.89345 7.4939 2.10567 6.42701C2.31789 5.36011 2.84171 4.3801 3.6109 3.61091C4.38009 2.84173 5.36009 2.3179 6.42699 2.10568C7.49389 1.89346 8.59975 2.00238 9.60475 2.41866C10.6097 2.83495 11.4687 3.5399 12.0731 4.44437C12.6774 5.34884 13 6.4122 13 7.5C13 8.95869 12.4205 10.3576 11.3891 11.3891C10.3576 12.4205 8.95868 13 7.49999 13Z"
                  fill="#565B62"
                />
              </g>
            </svg>
          </button>
          {showModal && (
            <Modal
              products={products}
              showModal={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      </div>
      <div className="mt-8">
        <table className="table-fixed w-full rounded-lg border-separate border-spacing-y-3">
          <thead>
            <tr className="text-white bg-[#17171C] text-[13px]">
              <th className="opacity-50 w-[350px] px-6 py-2 text-left font-normal rounded-tl rounded-bl">
                Product Name
              </th>
              <th className="opacity-50 w-[120px] px-6 py-2 text-center font-normal">
                Listed Stores
              </th>
              <th className="opacity-50 w-[120px] px-6 py-2 text-center font-normal">
                In Stock
              </th>
              <th className="opacity-50 w-[120px] px-6 py-2 text-center font-normal">
                Lowest Price
              </th>
              <th className="opacity-50 w-[100px] px-6 py-2 text-center font-normal">
                LPS
              </th>
              <th className="opacity-50 w-[120px] px-6 py-2 text-center font-normal">
                Highest Price
              </th>
              <th className="opacity-50 w-[100px] px-6 py-2 text-center font-normal">
                HPS
              </th>
              <th className="w-[180px] px-6 py-2 text-center font-normal rounded-tr rounded-br">
                <div className="flex justify-center items-center">
                  <div className="opacity-50 ">Price Volatility Index</div>
                  <Tooltip text="This index measures the consistency of product prices across various retailers. A higher value signifies greater price differences, highlighting potential price instability across retailers.">
                    {/* Render the Tooltip component with the desired tooltip text */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g opacity="0.5">
                        <path
                          d="M7.5 1.40625C2.36197 1.40625 1.40625 2.36197 1.40625 7.5C1.40625 12.6285 2.34129 13.5938 7.5 13.5938C12.6185 13.5938 13.5938 12.6285 13.5938 7.5C13.5938 2.3463 12.635 1.40625 7.5 1.40625ZM7.5 3.51885C8.11497 3.51885 8.61352 4.01739 8.61352 4.63236C8.61352 5.24733 8.115 5.74588 7.5 5.74588C6.885 5.74588 6.38648 5.24736 6.38648 4.63236C6.38648 4.01739 6.88503 3.51885 7.5 3.51885ZM9.4715 10.7642C9.4715 11.1585 9.14889 11.4812 8.75458 11.4812H7.88941H7.10458H6.24536C5.85105 11.4812 5.52844 11.1585 5.52844 10.7642V10.7479C5.52844 10.3535 5.85105 10.0309 6.24536 10.0309H6.38766V7.20513H5.88691C5.68978 7.20513 5.52847 7.04382 5.52847 6.84668V6.82119C5.52847 6.62405 5.68978 6.46274 5.88691 6.46274H7.10458H7.5924H7.88941C8.28372 6.46274 8.60634 6.78536 8.60634 7.17967V10.031H8.75458C9.14889 10.031 9.4715 10.3536 9.4715 10.7479V10.7642Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <TableRowLoader colSpan={8} />
            ) : (
              displayedProducts?.map((product, index) => (
                <TrackedProductRow key={index} {...product} />
              ))
            )}
          </tbody>
        </table>
        {products.length > 10 && (
          <button
            onClick={() => setShowAll(!showAll)}
            style={{
              transition: "box-shadow 0.3s", // Add transition for smooth effect
              boxShadow: "none", // Initial box-shadow
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow =
                "rgb(38, 39, 47) 0px 0px 0px 2px";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = "none";
            }}
            className="w-full bg-[#17171C] text-[13px] opacity-50 font-normal text-white rounded-md px-6 py-3 text-center focus:outline-none"
          >
            {showAll ? "Show Less" : "See all products"}
          </button>
        )}
      </div>
    </section>
  );
};

const DashboardPage = () => {
  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  // Initialize selectedCustomerId state with value from cookies if available, otherwise null
  const [selectedCustomerId, setSelectedCustomerId] = useState(() => {
    const customerIdFromCookie = Cookies.get("selectedCustomerId");
    return customerIdFromCookie || null;
  });

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleSelectCustomer = (customerId) => {
    console.log("Customer Id", customerId);
    setSelectedCustomerId(customerId);
  };

  return (
    <div className="flex gap-0 justify-between max-md:flex-wrap">
      <Sidebar active={"home"} />
      {/* <div
        style={{
          flex: 1,
          overflowY: "auto",
          paddingRight: "20px", // Adjust as needed
        }}
      > */}
      <div className="flex flex-col flex-1 self-start p-20 max-md:max-w-full h-screen overflow-y-auto">
        <header>
          <HeaderUserInfo
            userEmail={userData?.email}
            signOutText="Sign out"
            onSelectCustomer={handleSelectCustomer}
          />
        </header>
        <section className="shrink-0 mt-10 h-px bg-neutral-800 max-md:mt-10 max-md:max-w-full" />
        <RetailerLevelOverview customerId={selectedCustomerId} />
        <ProductTracker customerId={selectedCustomerId} />
      </div>
      {/* </div> */}
    </div>
  );
};

export default DashboardPage;
