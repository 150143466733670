
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './screens/auth/LoginPage';
import DashboardPage from './screens/dashboard/DashboardPage';
import { AuthContext } from './context/AuthContext';
import { Protected } from './components/Protected';
import ProductDetails from './screens/product/ProductDetails';
import { isMobile } from 'react-device-detect';
import MarketPulsePage from './screens/marketpulse/MarketPulsePage';


const MobileWarningMessage = () => {
  return (
    <div className="flex flex-col relative min-h-screen">
      <svg
        className="absolute top-0 left-0 w-full h-full z-0"
        width="2000"
        height="1500"
        viewBox="0 0 1477 1117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4" filter="url(#filter0_f_25_14)">
          <circle cx="738.5" cy="568.5" r="238.5" fill="#D9D9D9" />
        </g>
        <defs>
          <filter
            id="filter0_f_25_14"
            x="0"
            y="-170"
            width="1477"
            height="1477"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="250"
              result="effect1_foregroundBlur_25_14"
            />
          </filter>
        </defs>
      </svg>
      <div className="flex-grow flex flex-col items-center justify-center h-screen bg-black text-white text-opacity-80">
        <div className="text-center z-10">
          <div className="text-[45px] font-black  break-words mb-[52px]">
            PriceWho <span className="italic font-medium ">for Business</span>
          </div>

          <div className='px-[68px] text-gray-400 text-center font-roboto text-base font-light leading-8'>
            <p className='mb-10'>
              For the best experience, please access our Business Center on desktop.
            </p>
            <p>
              The platform is optimized for detailed data viewing on larger screens.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once



  const router = createBrowserRouter([
    {
      path: '/',
      element: <Protected><DashboardPage /></Protected>,
    },
    {
      path: '/dashboard',
      element: <Protected><DashboardPage /></Protected>,
    },
    {
      path: '/marketpulse',
      element: <Protected><MarketPulsePage /></Protected>,
    },
    {
      path: '/product/:productVariantId',
      element: <Protected><ProductDetails /></Protected>,
    },
    {
      path: '/login',
      element: <LoginPage />
    },
  ]);
  return (
    <div>
      {(isMobile || windowWidth < 768) ? <MobileWarningMessage /> :
        <div className='bg-[#1A1B20]'>
          <AuthContext><RouterProvider router={router} /></AuthContext>
        </div>}
    </div>

  );
}

export default App;
