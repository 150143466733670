import { useNavigate } from "react-router-dom";
import { signOut } from "../../services/firebase";

const HeaderStaticInfo = ({ mainText, subText, signOutText }) => {
    const navigate = useNavigate();
    const handleSignOut = async () => {
        await signOut();
        navigate("/login");
    }

    return (<div className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col">
            <div className="text-3xl font-bold text-white">
                <span>{mainText}</span>
            </div>
            <div className="text-lg text-zinc-600">{subText}</div>
        </div>
        <div className="flex gap-3 my-auto text-md cursor-pointer font-medium text-white" onClick={handleSignOut}>
            <div className="grow">{signOutText}</div>
            <img
                loading="lazy"
                src="/assets/icons/signout-icon.svg"
                className="aspect-square w-[22px]"
                alt="User"
            />
        </div>
    </div>)
}

export default HeaderStaticInfo;