import React, { useState } from "react";
import { signIn } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "../../styles/LoginPage.css";

const LoginPage = () => {
  const navigate = useNavigate();
  const arrowIcon = "/assets/icons/arrow-icon.svg";
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonImage, setButtonImage] = useState(arrowIcon);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      const { user } = await signIn(email, password);
      // Assuming you get a Firebase ID token after successful login
      const idToken = await user.getIdToken();
      // Store the token in a cookie
      Cookies.set("authToken", idToken);
      navigate("/"); // Redirect to DashboardPage on successful login
    } catch (error) {
      setShowError(true);
      setShowPassword(false);
      setButtonImage(arrowIcon);
      setEmail("");
      setPassword("");
      console.error("Login error:", error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (showPassword) {
        if (password === "") return;
        setButtonImage("/assets/icons/loader-icon.svg");
        handleLogin();
      } else {
        if (email === "") return;
        setShowPassword(true);
        setButtonImage("/assets/icons/arrow-icon.svg");
      }
    }
  };

  return (
    <div className="flex flex-col relative min-h-screen">
      <svg
        className="absolute top-0 left-0 w-full h-full z-0"
        width="2000"
        height="1500"
        viewBox="0 0 1477 1117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4" filter="url(#filter0_f_25_14)">
          <circle cx="738.5" cy="568.5" r="238.5" fill="#D9D9D9" />
        </g>
        <defs>
          <filter
            id="filter0_f_25_14"
            x="0"
            y="-170"
            width="1477"
            height="1477"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="250"
              result="effect1_foregroundBlur_25_14"
            />
          </filter>
        </defs>
      </svg>

      <div className="flex-grow flex flex-col items-center justify-center h-screen bg-black text-white text-opacity-80">
        <div className="text-center z-10">
          <div className="text-5xl font-black  break-words mb-[140px]">
            Competitor Intelligence Center <br /><span className="text-2xl italic font-regular">Data intelligence at your fingertips</span>
          </div>
          <p className="text-5xl font-bold mb-[30px]  break-words">
            Log In
          </p>
          <div className="flex justify-center items-center mb-5">
            <div className="flex rounded-full hover:scale-105 focus-within:scale-105 transition-transform duration-300 ease-in-out">
              {showPassword ? (
                <input
                  type="password"
                  placeholder="password"
                  value={password}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="placeholder-gray-600 bg-gray-800 border border-gray-800 border-2 p-5 text-l font-regular rounded-l-full w-[280px] h-[64px] focus:outline-none"
                />
              ) : (
                <input
                  type="email"
                  placeholder="account email"
                  value={email}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setShowError(false);
                    setEmail(e.target.value);
                  }}
                  className="placeholder-gray-600 bg-gray-800 border border-gray-800 border-2 p-5 text-l font-regular rounded-l-full w-[280px] h-[64px] focus:outline-none"
                />
              )}

              <button
                className="bg-gray-800 p-5 rounded-r-full focus:outline-none"
                onClick={() => {
                  if (showPassword) {
                    if (password === "") return;
                    setButtonImage("/assets/icons/loader-icon.svg");
                    handleLogin();
                  } else {
                    if (email === "") return;
                    setShowPassword(true);
                    setButtonImage("/assets/icons/arrow-icon.svg");
                  }
                }}
              >
                <img
                  src={buttonImage}
                  alt=""
                  className="w-6 h-6 transform scale-110"
                />
              </button>
            </div>
          </div>
          {showError && (
            <p className="text-sm font-light mb-1  break-words">
              Incorrect account email or password
            </p>
          )}
        </div>
        {/* Footer */}
        <footer className="absolute bottom-0 text-center text-white mb-[43px] text-xs font-light text-opacity-75">
          If you do not remember your account email or have trouble signing in,
          contact{" "}
          <a href="mailto:hello@pricewho.com" className="underline">
            info@datadroves.com
          </a>
        </footer>
      </div>
    </div>
    // <div>
    //     <h2>Login</h2>
    //     <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
    //     <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
    //     <button onClick={handleLogin}>Login</button>
    // </div>
  );
};

export default LoginPage;
