import axiosInstance from './api';

const productService = {
    getProductStores: async (productVariantId) => {
        let endpoint = `/product/GetProductStores/${productVariantId}`;

        try {
            const response = await axiosInstance.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }

    },

    getProductBenchmark: async (customerId, productVariantId) => {
        let endpoint = '/product/GetProductBenchmark';
        if (productVariantId) {
            endpoint += `/${productVariantId}`;
        }
        if (customerId) {
            endpoint += `/${customerId}`;
        }
        try {
            const response = await axiosInstance.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    },

    // Add more methods as needed for your application
};

export default productService;
