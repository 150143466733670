import React from "react";
import Sidebar from "../../components/common/Sidebar";
import HeaderStaticInfo from "../../components/common/HeaderStaticInfo";

const CategoryDetails = () => {
  return (
    <section
      className="mt-10 max-md:mt-10 max-md:max-w-full shadow-dark-box-shadow"
      style={{
        backgroundColor: "#131419",
        padding: "53px 40px 54px 60px",
        // boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.13)",
        borderRadius: "8px",
      }}
    >
      <div className="relative flex items-center mt-[30px]">
        <div className="flex items-center">
          <div className="mr-[16px] whitespace-nowrap">
            <h1 className="text-[20px] font-normal leading-none text-[white]">
              Filter
            </h1>
          </div>
          <div className="flex flex-wrap pr-[12px]">
            <span>
              <button className="flex flex-row items-center border border-solid bg-dark-button border-dark-button-border pl-[20px] pr-[20px] py-[4.75px] h-[35px] gap-[10px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.93229 2.50221C1.23696 3.15891 0.923077 4.28742 0.923077 6.30769C0.923077 8.32796 1.23696 9.45647 1.93229 10.1132C2.63634 10.7781 3.85211 11.0769 6 11.0769C8.14656 11.0769 9.36977 10.7789 10.0761 10.1439C10.7658 9.52386 11.0769 8.47387 11.0769 6.61539C11.0769 4.43461 10.761 3.22713 10.0599 2.53314C9.35765 1.83796 8.14897 1.53846 6 1.53846C3.85211 1.53846 2.63634 1.83727 1.93229 2.50221ZM1.29848 1.83112C2.28674 0.897769 3.8402 0.615385 6 0.615385C8.15872 0.615385 9.71928 0.897084 10.7093 1.87712C11.7006 2.85835 12 4.42009 12 6.61539C12 8.51758 11.6957 9.92913 10.6932 10.8304C9.70715 11.7168 8.16113 12 6 12C3.8402 12 2.28674 11.7176 1.29848 10.7843C0.301503 9.84267 0 8.35579 0 6.30769C0 4.25959 0.301503 2.77271 1.29848 1.83112Z"
                    fill="#868F97"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.84615 0C8.10106 0 8.30769 0.206638 8.30769 0.461538V2.30769C8.30769 2.56259 8.10106 2.76923 7.84615 2.76923C7.59125 2.76923 7.38462 2.56259 7.38462 2.30769V0.461538C7.38462 0.206638 7.59125 0 7.84615 0Z"
                    fill="#868F97"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.15385 0C4.40875 0 4.61538 0.206638 4.61538 0.461538V2.30769C4.61538 2.56259 4.40875 2.76923 4.15385 2.76923C3.89895 2.76923 3.69231 2.56259 3.69231 2.30769V0.461538C3.69231 0.206638 3.89895 0 4.15385 0Z"
                    fill="#868F97"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.92308 4.30769C2.92308 4.05279 3.12971 3.84615 3.38462 3.84615H8.61539C8.87029 3.84615 9.07692 4.05279 9.07692 4.30769C9.07692 4.56259 8.87029 4.76923 8.61539 4.76923H3.38462C3.12971 4.76923 2.92308 4.56259 2.92308 4.30769Z"
                    fill="#868F97"
                  />
                </svg>
                Past 30 days
              </button>
            </span>
            <span>
              <button className="ml-[15px] flex flex-row items-center border border-solid bg-dark-button border-dark-button-border pl-[20px] pr-[20px] py-[4.75px] h-[35px] gap-[10px] bg-[rgba(255,_255,_255,_0.03)] rounded-[99px] text-[14px] leading-[132%] text-[rgb(134,_143,_151)] [transition:color_0.2s_ease_0s,_background_0.2s_ease_0s]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M1 2.81066C1 1.72426 3.33333 1 8 1C12.6667 1 15 1.72426 15 2.81066C15 4.5 9.55556 6.6 9.55556 8.24262C9.55556 9.6912 9.55556 9.6912 9.55556 10.7776C9.55556 11.864 6.44444 12.9504 6.44444 11.5018C6.44444 10.0533 6.44444 10.0533 6.44444 8.24262C6.44444 6.6 1 4.5 1 2.81066Z"
                    stroke="#565B62"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Consumer Electronics
              </button>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

const NationalTrendingTopics = () => {
  return (
    <section
      className="mt-10 max-md:mt-10 max-md:max-w-full shadow-dark-box-shadow"
      style={{
        backgroundColor: "#131419",
        padding: "53px 40px 54px 60px",
        // boxShadow: "0px 25px 35px rgba(0, 0, 0, 0.13)",
        borderRadius: "8px",
      }}
    >
      <div className="flex justify-between items-center">
        <h1 className="flex text-lg font-bold text-white leading-[21px]">
          National Trending Topics
          <div className="flex text-[8px] flex items-center w-[64px] h-[18.173px] flex-shrink-0 rounded-[3px] bg-dark-button-border text-[#8C8D8F] not-italic font-bold leading-[normal] m-auto p-[8px] relative -left-[-10px]">
            Past 30 days
          </div>
        </h1>
      </div>
      <div class="flex justify-between mt-8">
        <table class="min-w-max hidden-header w-full rounded-lg border-separate border-spacing-y-3">
          <tbody>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
          </tbody>
        </table>
        <table class="px-[10px] min-w-max hidden-header w-full rounded-lg border-separate border-spacing-y-3">
          <tbody>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
          </tbody>
        </table>
        <table class="min-w-max hidden-header w-full rounded-lg border-separate border-spacing-y-3">
          <tbody>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
            <tr
              className="cursor-pointer text-white bg-[linear-gradient(90deg,_#131419_54%,_#1D1E25_100%)] rounded-[3px]"
              style={{
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                boxShadow: "none", // Initial box-shadow
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "rgb(38, 39, 47) 0px 0px 0px 2px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <td className="px-6 py-2 rounded-tl rounded-bl">
                <div className="flex">
                  <div className="truncate ...">Consumer Electronicx</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center text-xs rounded-tr rounded-br">
                iPhone 15 pro max
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

const MarketPulsePage = () => {
  const getCurrentDate = () => {
    const date = new Date(); // Assuming the date is '12 March 2024'
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  return (
    <div className="flex gap-0 justify-between max-md:flex-wrap">
      <Sidebar active={"marketpulse"} />

      <div className="flex flex-col flex-1 self-start p-20 max-md:max-w-full h-screen overflow-y-auto">
        <header>
          <HeaderStaticInfo
            mainText="Market Pulse"
            subText={getCurrentDate()}
            signOutText="Sign Out"
          />
        </header>
        <section className="shrink-0 mt-10 h-px bg-neutral-800 max-md:mt-10 max-md:max-w-full" />
        <NationalTrendingTopics />
        <CategoryDetails />
      </div>
      {/* </div> */}
    </div>
  );
};

export default MarketPulsePage;
