// src/services/apiService.js
import axiosInstance from './api';

const customerService = {
    getCustomer: async (customerId) => {
        let endpoint = '/customer/GetCustomer';
        if (customerId) {
            endpoint += `/${customerId}`;
        }
        try {
            const response = await axiosInstance.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    },
};

export default customerService;
