import React, { useState } from 'react';
import "../../styles/Tooltip.css";

const Tooltip = ({ children, text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="ml-1 flex items-center relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {showTooltip && (
                <div className="absolute z-10 p-4 -translate-x-[47%] bottom-[35px] text-left min-w-[211px] text-[10px] font-light text-white transition-opacity duration-300 bg-black rounded-lg shadow-sm opacity-100 tooltip">
                    <div className='opacity-50'>{text}</div>
                    <div className="arrow-down" />
                </div>
            )}
            {children}
        </div>
    );
};

export default Tooltip;
