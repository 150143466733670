import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ active }) => {
    return (
        <div className="flex bg-[#16181C]">
            <div className="relative flex flex-col justify-between items-center w-[78px] h-screen top-0 pt-[25px] px-0 pb-[56px] z-10 border-r-2 border-neutral-800">
                <button>
                    <img src="logo.svg" alt="Logo" className="top-[30px] absolute left-[20px]" />
                </button>
                <div className="relative w-full text-center">
                    <div className={`absolute right-0 w-[2px] h-[40px] mx-[auto] my-0 bg-[rgb(71,_159,_250)] origin-[center_top]`} style={{ transition: 'transform 0.15s ease 0s', transform: `${active === 'home' ? 'translateY(0)' : active === "marketpulse" ? 'translateY(48px)' : ''}` }} />
                    <span className="mb-[8px] block text-center">
                        <Link
                            className={`relative flex items-center justify-center h-[40px] w-full z-10 after:content-[''] after:absolute after:right-0 after:top-0 after:bottom-0 after:w-px after:opacity-0 after:bg-[rgb(71,_159,_250)] after:[transition:opacity_0.25s_var(--ease-out)] before:content-[''] before:absolute before:mx-[auto] before:my-0 before:w-[32px] before:h-[32px] before:rounded-[5px] before:opacity-0 before:bg-[rgb(134,_143,_151)] before:[transition:opacity_0.25s_var(--ease-out)]}`}
                            to="/dashboard"
                        >
                            <img src="/assets/icons/home-icon.svg" alt="Home" className={`w-8 h-8 ${active === "home" ? "opacity-100" : "opacity-50"}`} />
                        </Link>
                    </span>
                    {/* <span className="mb-[8px] block text-center">
                        <Link
                            className={`relative flex items-center justify-center h-[40px] w-full z-10 after:content-[''] after:absolute after:right-0 after:top-0 after:bottom-0 after:w-px after:opacity-0 after:bg-[rgb(71,_159,_250)] after:[transition:opacity_0.25s_var(--ease-out)] before:content-[''] before:absolute before:mx-[auto] before:my-0 before:w-[32px] before:h-[32px] before:rounded-[5px] before:opacity-0 before:bg-[rgb(134,_143,_151)] before:[transition:opacity_0.25s_var(--ease-out)]}`}
                            to="/marketpulse"
                        >
                            <img src="/assets/icons/market-pulse-icon.svg" alt="MarketPulse" className={`w-8 h-8 ${active === "marketpulse" ? "opacity-100" : "opacity-50"}`} />
                        </Link>
                    </span> */}
                    <span className="mb-[8px] block text-center">
                        <Link
                            className={`relative flex items-center justify-center h-[40px] w-full z-10 after:content-[''] after:absolute after:right-0 after:top-0 after:bottom-0 after:w-px after:opacity-0 after:bg-[rgb(71,_159,_250)] after:[transition:opacity_0.25s_var(--ease-out)] before:content-[''] before:absolute before:mx-[auto] before:my-0 before:w-[32px] before:h-[32px] before:rounded-[5px] before:opacity-0 before:bg-[rgb(134,_143,_151)] before:[transition:opacity_0.25s_var(--ease-out)]}`}
                            to="/"
                        >
                            <img src="/assets/icons/setting-icon.svg" alt="Settings" className={`w-8 h-8 ${active === "settings" ? "opacity-100" : "opacity-50"}`} />
                        </Link>
                    </span>
                </div>
                <div />
            </div>
        </div >
    )
};

export default Sidebar;
