import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBQSzQdD5eBbpacus32sWs_1Dlf8sE6gp8",
    authDomain: "pw-business-bfb81.firebaseapp.com",
    projectId: "pw-business-bfb81",
    storageBucket: "pw-business-bfb81.appspot.com",
    messagingSenderId: "388860336026",
    appId: "1:388860336026:web:eaff6f9016c2313af28c23",
    measurementId: "G-5LQ8WJ5KGN"
};

firebase.initializeApp(firebaseConfig);

// Sign up with email and password
const signUp = async (email, password) => {
    return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

// Sign in with email and password
const signIn = async (email, password) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
};

// Sign out
const signOut = async () => {
    return await firebase.auth().signOut();
};

export { signUp, signIn, signOut };
