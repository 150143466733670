import { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";


export const Context = createContext();

export function AuthContext({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setLoading(false);
            if (user)
                setUser(user);
            else
                setUser(null);
        });

        return () => {
            if (unsubscribe) unsubscribe();
        }
    }, []);

    const values = {
        user,
        setUser
    }

    return (
        <Context.Provider value={values}>
            {!loading && children}
        </Context.Provider>
    );
}
